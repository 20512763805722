.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*bottom: var(--footer-height);*/
    z-index: 101;
    background: linear-gradient(0deg, #0C0B1A, #0C0B1A);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        animation: spin 1s linear infinite;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
